import { createStore } from "vuex";
import { db, auth } from "../firebase";

import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";

import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  where,
} from "firebase/firestore/lite";
import api from "@/boot/api";

export default createStore({
  state: {
    loading: false,
    equipments: [],
    transport: [],
    soldMachines: [],
    rentedMachines: [],
    huilles: [],
    ads: [],
    categories: [],
    machine_categories: [],
    resultaFindByCategore: [],
    detileItem: {},
    errAuth: false,
    user: {},
    Listcatigores: [],
    sharchData: [],
    ListMachine_categories: [],
  },
  getters: {},
  mutations: {
    addAllAds(
      state,
      { ListAds, equipments, transport, huilles, soldMachines, rentedMachines }
    ) {
      console.log("equipments", equipments);
      console.log("transport", transport);
      console.log("huilles", huilles);
      console.log("soldMachines", soldMachines);
      console.log("rentedMachines", rentedMachines);

      state.equipments.push(equipments);
      state.transport.push(transport);
      state.huilles.push(huilles);
      state.soldMachines.push(soldMachines);
      state.rentedMachines.push(rentedMachines);
      state.ads.push(ListAds);
    },
    addAllCategories(state, categoriesList) {
      state.categories.push(categoriesList);
    },
    VideUser(state) {
      state.user = null;
    },
    addAllmachine_categories(state, machine_categories) {
      state.machine_categories.push(machine_categories);
    },
    Chamge_Loding(state, type) {
      state.loading = type;
    },
  },
  actions: {
    // ==================================================================================

    async getAds() {
      let ListAds = [];
      let equipments = [];
      let transport = [];
      let soldMachines = [];
      let rentedMachines = [];

      await api.get('/adds-not-auth').then(res => {
        equipments.push(res.data.addsPieces);
        rentedMachines.push(res.data.addsLouer);
        soldMachines.push(res.data.addsVendre);
        transport.push(res.data.addsTransport);
        ListAds.push(res.data.adds);

        this.commit("addAllAds", {
          ListAds,
          equipments,
          transport,
          soldMachines,
          rentedMachines,
        });

      })
      this.commit("Chamge_Loding", false);

    },


    // ==================================================================================
    async setLoading({ commit }, type) {
      commit("Chamge_Loding", type);
    },
    // ==================================================================================

    // ==================================================================================
    async findByCategore({ state }, uid) {
      state.resultaFindByCategore = [];
      let ListAds = [];
      let Machincategory;
      const ads = query(
        collection(db, "ads"),
        where("status", "==", 1),
        where("machineCategory", "==", uid)
      );
      const adsSnapshot = await getDocs(ads);
      const adsList = adsSnapshot.docs.map((doc) => doc.data());

      // get users de annonse
      adsList.forEach(async (e) => {
        // cleint
        const userDoc = doc(db, "users", e.client);
        const user = await getDoc(userDoc);
        // categore
        const categoryDoc = doc(db, "categories", e.category);
        const category = await getDoc(categoryDoc);
        // machin catigore
        try {
          const MachincategoryDoc = doc(
            db,
            "machine_categories",
            e.machineCategory
          );
          Machincategory = await (await getDoc(MachincategoryDoc)).data();
        } catch (e) {
          console.log("err");
        }
        // data
        let item = {
          uid: e.uid,
          title: e.title,
          image: e.images,
          city: e.city,
          description: e.description,
          price: e.price,
          type: e.type,
          client: user.data(),
          category: category.data(),
          Machincategory: Machincategory,
          createdAt: new Date(e.createdAt).toLocaleDateString("fr-FR"),
          status: e.status,
        };
        ListAds.push(item);
      });

      state.resultaFindByCategore.push(ListAds);
    },
    // ==================================================================================
    async voirPlus({ state }, ads) {
      state.resultaFindByCategore = [];
      state.resultaFindByCategore.push(ads);
    },
    // ==================================================================================
    async detail({ state }, ad) {
      state.detileItem = null;
      state.detileItem = ad;
    },
    // ==================================================================================


    // ==================================================================================
    async getCatigores() {
      await api.get("categories").then(res => {
        this.commit("addAllCategories", res.data);
        res.data.map((e) => {
          this.state.Listcatigores.push({ id: e.id, text: e.name });
          console.log(this.state.Listcatigores);
        });
      }).catch(e => {
        console.log(e);
      })

    },
    // ==================================================================================
    async getMachine_categories() {
      await api.get("machine-categories").then(res => {
        this.commit("addAllmachine_categories", res.data);
        res.data.map((e) => {
          this.state.ListMachine_categories.push({ id: e.id, text: e.name });
          console.log(this.state.ListMachine_categories);
        });
      }).catch(e => {
        console.log(e);
      })
    },
    // =======================================loginWithGoogle===========================================
    async loginWithGoogle({ state }) {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider)
        .then(async (res) => {

          await api.post('/login', {
            email: res.user.email,
            password: "loginWithGoogle"
          })
            .then(async (loginRes) => {
              localStorage.setItem("user", JSON.stringify(loginRes.data.user));
              localStorage.setItem("token", loginRes.data.token);
              state.user = loginRes.data.user;
              location.reload();
            })
            .catch(async () => {
              await api.post('/register', {
                name: res.user.displayName,
                email: res.user.email,
                password: "loginWithGoogle",
                phone: res.user.phoneNumber ?? "06",
              })
                .then(async (resRegister) => {
                  localStorage.setItem("user", JSON.stringify(resRegister.data.user));
                  localStorage.setItem("token", resRegister.data.token);
                  state.user = resRegister.data.user;
                  location.reload();
                })
                .catch((e) => {
                  console.log(e);
                  state.errAuth = true;
                  setTimeout(() => {
                    state.errAuth = false;
                  }, 2000);
                });
            });
        })
        .catch((e) => {
          console.log(e);
          state.errAuth = true;
          setTimeout(() => {
            state.errAuth = false;
          }, 2000);
        });
    },
    // =======================================loginWithFacebook===========================================
    async loginWithFacebook() {
      const provider = new FacebookAuthProvider();
      await signInWithPopup(auth, provider).then(async (res) => {
        console.log(res);
      });
    },
    // =======================================loginWithApple===========================================
    async loginWithApple() { },
    // =======================================loginWithApple===========================================

    async login({ state }, data) {
      await api.post('/login', data)
        .then(async (res) => {
          if(res.status === 200){
          localStorage.setItem("user", JSON.stringify(res.data.user));
          localStorage.setItem("token", res.data.token);
          state.user = res.data.user;
          location.reload();
        }else{
          state.errAuth = true;
          setTimeout(() => {
            state.errAuth = false;
          }, 2000);
        }
        })
        .catch((e) => {
          console.log(e);
          state.errAuth = true;
          setTimeout(() => {
            state.errAuth = false;
          }, 2000);
        });
    },
    // ==================================================================================
    async register({ state }, data) {
      await api.post('/register', data)
        .then(async (res) => {
          localStorage.setItem("user", JSON.stringify(res.data.user));
          localStorage.setItem("token", res.data.token);
          state.user = res.data.user;
          location.reload();
        })
        .catch((e) => {
          console.log(e);
          state.errAuth = true;
          setTimeout(() => {
            state.errAuth = false;
          }, 2000);
        });
    },
    // =======================================getdata cherch===========================================
  },
  modules: {},
});

