<script>

export default {
  methods: {
    async getData() {
      await this.$store.dispatch("getCatigores");
      await this.$store.dispatch("getMachine_categories");
    },
  },
  async mounted() {
    this.getData();
    this.$i18n.locale =
      localStorage.getItem("lang") == null ? "fr" : localStorage.getItem("lang");
    // Get a list of cities from your database
  },
};
</script>
<template>
  <div>
    <!-- <model-detail /> -->
    <router-view />
  </div>
</template>
<style></style>
