import axios from "axios";

const api = axios.create({

    baseURL: 'https://smartcoding.ma/mo9ef_backend/public/api/',
    // baseURL : 'http://localhost:8000/api/',

});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export default api;
